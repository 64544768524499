import { FC } from 'react';
import cn from 'classnames';

import { ItemsLayout, PsychicCardAppearance } from 'constants/enums';
import { Block } from 'src/__generated__/graphqlTypes';
import { BannerCarousel } from 'entities/FlatBannerContent';

import styles from './PsychicsSetMediumSizeImages.module.scss';
import type { IBannerWrapper } from './declarations';
import { useBannerImages, useEmbeddedItemPosition } from './lib/hooks';

const BannerWrapper: FC<IBannerWrapper> = ({
  banner,
  children,
  itemsLayout,
  shouldRenderBanner,
  psychicCardAppearance,
}) => {
  const isTile = psychicCardAppearance === PsychicCardAppearance.TILE;
  const isWrongConditionTile = isTile && !itemsLayout?.includes('grid');
  const gridRowStart = useEmbeddedItemPosition(psychicCardAppearance, banner?.properties);
  const bannerImages = useBannerImages(banner?.contentTypesCollection?.items as Array<Block>);

  if (!bannerImages || isWrongConditionTile || !shouldRenderBanner) {
    return <>{children}</>;
  }

  const tileGridStyles = {
    [styles.tileGrid2]: isTile && itemsLayout === ItemsLayout.GRID_2,
    [styles.tileGrid3]: isTile
      && (itemsLayout === ItemsLayout.GRID_3 || itemsLayout === ItemsLayout.GRID),
  };

  return (
    <>
      <li
        style={{ gridRowStart }}
        className={cn(
          styles.injected,
          styles[psychicCardAppearance.toLowerCase()],
          tileGridStyles,
        )}
      >
        <BannerCarousel
          bannerImages={bannerImages}
          className={styles.banner}
        />
      </li>
      {children}
    </>
  );
};

export default BannerWrapper;
