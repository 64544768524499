import {
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';

import type { RightPsychic, SectionExtraDataType } from 'types/objectTypes';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';

import { useGetApiCaller, useMappedPsychics } from './api-switcher';
import { useFetchPsychicsOnIntersection, useRefetchPsychicsOnSignInOrSignOut } from './psychics';
import { useContextDispatch, useHandlePsychicsRefetch } from './hooks';

import { ErrorMessage } from '../config/constants';

export const useLocalSwr = (
  key: string | null = null,
  fetcher: (() => Promise<any>) | null = null,
) => {
  const dispatch = useContextDispatch();
  const [shouldCall, setCallState] = useState<boolean>(true);
  const keyOrNull = useMemo(() => (shouldCall ? key : null), [key, shouldCall]);
  const { data, error, isLoading } = useSWR(keyOrNull, fetcher, { revalidateOnFocus: false });
  const callApi = async () => {
    setCallState(true);
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    const isObjectHasRelevantKeys = Object.values(error)
      .filter((value) => value !== null && value !== undefined).length > 0;
    const isErrorSimpleObject = typeof error === 'object'
      && !error.message
      && !error.stack
      && !isObjectHasRelevantKeys;

    if (isErrorSimpleObject || error === '{}') {
      return;
    }

    if (error.message === ErrorMessage.NO_USER
      || error.message === ErrorMessage.NO_PSYCHICS) {
      return dispatch({ payload: true, type: 'shouldHideSection' });
    }

    const context = JSON.stringify({
      errorType: typeof error || 'undefined',
      errorLength: `${error?.length}`,
      error,
      conditions: {
        1: typeof error === 'object',
        2: !error.message,
        3: !error.stack,
        4: isObjectHasRelevantKeys,
      },
      entries: Object.entries(error),
      message: 'Error while fetching psychics',
      key: `${keyOrNull}`,
    }, null, 2);
    console.error(context);
  }, [error]);

  return { data, error, isLoading, callApi };
};

export const usePsychics = (
  extraData: SectionExtraDataType,
  sectionRef: RefObject<HTMLElement>,
) => {
  const [mutableData, setMutableData] = useState<SectionExtraDataType>(extraData);
  const [psychics, setPsychics] = useState<Array<RightPsychic>>([]);

  const { key, call } = useGetApiCaller(mutableData) || {};

  const { data, callApi } = useLocalSwr(key, call);

  useHandlePsychicsRefetch(setMutableData);

  useMappedPsychics(data, mutableData, setPsychics);

  const idList: Array<number> = (psychics || [])?.flatMap((psychic) => psychic.extId);
  usePsychicStatuses(idList, setPsychics);

  useRefetchPsychicsOnSignInOrSignOut(callApi, psychics);

  useFetchPsychicsOnIntersection(sectionRef, callApi);

  return { psychics };
};
