import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import classes from 'src/styles/_commonClasses.module.scss';
import { Status } from 'constants/enums';

import { TextStatusComponentInterface } from '../config/declarations';

const Text: FC<TextStatusComponentInterface> = ({
  status,
  psychic,
  className,
  offsetClass,
  psychicFrame,
  wrapperClass,
  smallTextClass,
}) => {
  const textStyles = cn(className, classes.statusText);
  const [backInTime, setBackInTime] = useState<number>(psychic?.onBreakMinutes);
  const wasOnBreakRef = useRef<boolean>(false);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (status === Status.ON_BREAK) {
      if (!wasOnBreakRef.current && psychic?.onBreakMinutes !== backInTime) {
        setBackInTime(psychic?.onBreakMinutes);
      }

      wasOnBreakRef.current = true;

      timer.current = setTimeout(() => {
        if (backInTime > 0) {
          setBackInTime((prev) => prev - 1);
        }
      }, 60 * 1000);

      if (!backInTime) {
        clearTimeout(timer.current);
      }

      return;
    }

    if (wasOnBreakRef.current) {
      wasOnBreakRef.current = false;
      setBackInTime(0);
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [status, psychic.onBreakMinutes, backInTime, wasOnBreakRef]);

  if (status === Status.ON_BREAK) {
    const backIn = replaceContentfulVariables(
      psychicFrame?.backIn!,
      { min: `${backInTime}` },
    );

    return (
      <div className={wrapperClass}>
        <span className={cn(textStyles, classes.statusTextBusy)}>
          {Status.DISPLAY_ON_BREAK}
        </span>
        {backIn && !psychic.isChatOnly && (
          <span className={smallTextClass || classes.statusTextSmall}>
            {backIn}
          </span>
        )}
      </div>
    );
  }

  const simpleStyles = cn(
    textStyles,
    classes[`statusText${capitalizeFirstLetter(status)}`],
    offsetClass,
  );

  return (
    <span className={simpleStyles}>
      {capitalizeFirstLetter(status)}
    </span>
  );
};

export default Text;
