import { FC } from 'react';
import cn from 'classnames';

import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { StatusIndicatorWithText, PsychicNameWithIcon } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPriceComponent, IPsychicPhotoModule } from '../config/declarations';
import { getRatePerMinute } from '../lib';

const priceStyles = {
  wrapper: styles.price,
  common: styles.priceCommon,
  commonWithDiscount: styles.priceCrossed,
  discount: styles.priceDiscount,
};

/* Nested Component */
const PriceComponent: FC<IPriceComponent> = ({ ratePerMinute, ratePerMinuteText }) => {
  const minText = `/${ratePerMinuteText}`;

  return (
    <PsychicRate
      pricePerMinute={ratePerMinute.price}
      priceWithDiscountPerMinute={ratePerMinute.discount}
      classNames={priceStyles}
      time={minText}
    />
  );
};

const PhotoWithRate: FC<IPsychicPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => {
  const rates = getRatePerMinute(psychic);
  const labelIcon = psychicSkeleton?.labelsCollection?.items
    ?.find((item) => item?.slug === 'verification-icon');

  return (
    <div className={styles.photoWithRate}>
      <PsychicCardImage
        psychic={psychicSkeleton}
        image="external"
        src={src}
        alt={psychic.psychicName}
        className={cn(styles.image, imageClassName)}
      />
      <div className={styles.stars}>
        <PsychicNameWithIcon
          psychicName={psychic.psychicName}
          image={labelIcon?.image!}
          wrapperClass={cn(styles.psychicName, styles.name)}
          className={styles.psychicIcon}
        />
      </div>
      <div className={styles.rateStatus}>
        <PriceComponent
          ratePerMinute={rates}
          ratePerMinuteText={psychicSkeleton.ratePerMinute}
        />
        <StatusIndicatorWithText
          chatStatus={psychic.chatStatus}
          phoneStatus={psychic.phoneStatus}
          wrapperClass={styles.status}
        />
      </div>
    </div>
  );
};

export default PhotoWithRate;
