import { FC, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Account } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { DataButton } from 'components/Shared/SharedComponents';
import { setLoadingState } from 'app-redux/actions/appActions';
import { Logger } from 'lib/logger';
import { signOut } from 'lib/auth.service';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import styles from './styles.module.scss';

import type { IECView } from '../config/declarations';

const ECView: FC<IECView> = ({ auth }) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useDispatch();
  const router = useCustomRouter();

  if (!auth || !auth.collapsed || auth.collapsed.__typename === 'LoginForm') {
    return null;
  }

  const { signOut: button } = auth.collapsed as Account;

  if (!button) {
    return null;
  }

  const onSignOut = async () => {
    try {
      dispatch(setLoadingState(true));
      await signOut(user);
      const url = router.asPath;
      router.replace(`${url}?t=${new Date().getTime()}`, url, { keepQuery: true, withReload: true });
    } catch (e) {
      Logger.error(e);
    }
  };

  const onSignOutKeyPress = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      onSignOut();
    }
  };

  return (
    <DataButton
      link={button}
      className={styles.signOut}
      onClick={onSignOut}
      onKeyPress={onSignOutKeyPress}
    >
      {button.title}
    </DataButton>
  );
};

export default ECView;
