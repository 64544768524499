import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'cookie';

import type { Store } from 'app-redux/types/storeTypes';
import { Logger } from 'lib/logger';
import type { UserType } from 'types/objectTypes';
import { areCookiesEnabled } from 'src/shared/lib/customer/cookie';

/** In some edge cases users can be redirected to NC page instead of EC
 * to avoid it this hook compares cookie and store info and reload
 * the page if the page was from cache when we needed a fresh page
 */
export const useAuthCacheHandler = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  useEffect(() => {
    try {
      const cookies = cookie.parse(document.cookie);
      const cookieUser = JSON.parse(cookies.auth || '{}') as UserType;
      const isSharedLpPath = window.location.pathname.includes('lpec');

      if (cookieUser?.custId !== user?.custId && areCookiesEnabled() && !isSharedLpPath) {
        window.location.reload();
      }
    } catch (e) {
      Logger.error(e);
    }
  }, [user]);
};
