/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  FC,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Carousel } from 'react-responsive-carousel';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import { Link } from 'src/__generated__/graphqlTypes';

import styles from './styles.module.scss';
import Image from './Image';

import type { IBanner } from '../config/declarations';

const renderIndicator = (
  clickHandler: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void,
  isSelected: boolean,
  index: number,
  label: string,
) => (
  <li
    key={index}
    onClick={clickHandler}
    onKeyPress={clickHandler}
    title={label}
    aria-label={label}
    className={cn(
      isSelected && styles.bannerIndicatorSelected,
      styles.bannerIndicator,
      classes.cursorPointer,
    )}
  />
);

/* Main Component */
const BannerCarousel: FC<IBanner> = ({ bannerImages, className }) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const router = useRouter();
  const redirectToUrl = (src: Link['src']) => {
    if (src) {
      router.push(src);
    }
  };

  if (!bannerImages) {
    return null;
  }

  const imagesComponents = bannerImages
    .map((imageObj) => (
      <Image
        key={imageObj?.entryName}
        imageObject={imageObj!}
        isMobileViewWidth={isMobileViewWidth}
        link={imageObj?.link}
        redirectToUrl={redirectToUrl}
      />
    ));

  return (
    <div className={cn(styles.banner, className)}>
      {(bannerImages.length === 1)
        ? (imagesComponents)
        : (
          <Carousel
            showStatus={false}
            showArrows={false}
            infiniteLoop
            autoPlay
            stopOnHover
            renderIndicator={renderIndicator}
          >
            {imagesComponents}
          </Carousel>
        )}
    </div>
  );
};

export default BannerCarousel;
