import { ONE_DAY_IN_MS } from 'constants/constants';
import { getDaysInMonth } from 'lib/date.service';

import { COLUMNS, WEEKDAYS_AMOUNT } from '../config/constants';

export const getDayByIndex = (date: Date = new Date(), index: number = 1) => new Date(
  date.getFullYear(),
  date.getMonth(),
  index,
);

export const splitDates = (dates: Array<Date>) => {
  const newArray = [...dates];
  const twoDArray: Array<Array<Date>> = [];

  while (newArray.length) {
    twoDArray.push(newArray.splice(0, COLUMNS));
  }

  return twoDArray;
};

export const compareDates = (first: Date, second: Date) => (first.getDate() === second.getDate())
  && (first.getMonth() === second.getMonth());

export const checkIsTooEarly = (
  now: Date,
  date: Date | null,
) => {
  if (!date) {
    return null;
  }

  const earliestFutureDate = now.getTime() + ONE_DAY_IN_MS * (WEEKDAYS_AMOUNT - 1);

  return date.getTime() > earliestFutureDate;
};

export const checkIsYesterdays = (now: Date, date: Date | null) => {
  if (!date) {
    return null;
  }

  const isRightYear = now.getFullYear() > date.getFullYear();
  const isRightMonth = (now.getMonth() > date.getMonth()
  && now.getFullYear() === date.getFullYear());
  const isRightDay = (now.getDate() > date.getDate() && now.getMonth() === date.getMonth()
  && now.getFullYear() === date.getFullYear());

  return (isRightYear || isRightMonth || isRightDay);
};

export const getCellFlags = (now: Date, date: Date) => {
  const isCurrent = now.getDate() === date.getDate()
              && now.getMonth() === date.getMonth();
  const isYesterdays = checkIsYesterdays(now, date);
  const isTooEarly = checkIsTooEarly(now, date);

  return {
    isCurrent,
    isYesterdays,
    isTooEarly,
  };
};

export const getDatesFromMonthEnd = (
  monthIndex: number,
  amount: number,
  date: Date = new Date(),
) => {
  const clonedDate = new Date(date.getFullYear(), monthIndex);
  const daysAmount = getDaysInMonth(clonedDate);
  const requiredDates: Array<Date> = [];

  for (let i = daysAmount - amount; i < daysAmount; i++) {
    requiredDates.push(new Date(
      clonedDate.getFullYear(),
      clonedDate.getMonth(),
      i + 1,
    ));
  }

  return requiredDates;
};

export const getDatesFromMonthStart = (
  monthIndex: number,
  amount: number,
  date: Date = new Date(),
) => {
  const clonedDate = new Date(date.getFullYear(), monthIndex);
  const requiredDates: Array<Date> = [];

  for (let i = 1; i <= amount; i++) {
    requiredDates.push(new Date(
      clonedDate.getFullYear(),
      clonedDate.getMonth(),
      i,
    ));
  }

  return requiredDates;
};

export const makeCalendarDateKey = (date: Date) => `${date.getDate()}-${date.getMonth()}`;
