import cookie from 'js-cookie';

import { Logger } from 'lib/logger';

export const getVisitedPsychicsIds = () => {
  const psychicsIds = decodeURI(cookie.get('extIds') || '');

  try {
    if (!psychicsIds) {
      return [];
    }

    const idsArray = JSON.parse(psychicsIds) as Array<string>;

    if (!Array.isArray(idsArray)) {
      return [];
    }

    return idsArray;
  } catch (e) {
    Logger.warn(e, `Wrong psychics ids: ${psychicsIds}`);

    return [];
  }
};

export const areCookiesEnabled = () => {
  document.cookie = 'testcookie=1; path=/';
  const cookiesEnabled = document.cookie.indexOf('testcookie=') !== -1;
  document.cookie = 'testcookie=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';

  return cookiesEnabled;
};
