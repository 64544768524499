import { FC } from 'react';
import cn from 'classnames';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';

import styles from './styles.module.scss';

import type { IImage } from '../config/declarations';

const Image: FC<IImage> = ({
  imageObject,
  isMobileViewWidth,
  link,
  redirectToUrl,
}) => {
  const [desktop, mobile] = imageObject?.imagesCollection?.items || [];
  const src = isMobileViewWidth ? mobile?.image?.url : desktop?.image?.url;

  const onClick = () => redirectToUrl(link?.src);

  if (!link) {
    return (
      <div className={styles.bannerImageWrapper}>
        <WebpImage
          image="external"
          src={src!}
          className={styles.bannerImage}
          alt="Slide"
        />
      </div>
    );
  }

  return (
    <DataButton
      link={link!}
      onClick={onClick}
      onKeyDown={onClick}
      className={cn(classes.cursorPointer, styles.bannerImageWrapper)}
    >
      <WebpImage
        image="external"
        src={src!}
        className={styles.bannerImage}
        alt="Slide"
      />
    </DataButton>
  );
};

export default Image;
