import {
  BLOCKS,
  MARKS,
  Block as BlockNode,
  Text as TextNode,
  Document as DocumentNode,
} from '@contentful/rich-text-types';
import { FC } from 'react';
import cn from 'classnames';

import styles from 'src/components/Sections/NcOfferPackages/NcOfferPackages.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { mapBlocksSpan } from 'lib/richTextMappers';
import {
  Block,
  BlockContent,
  BlockRichTitle,
} from 'src/__generated__/graphqlTypes';
import { CardNcOfferPackagesInterface } from 'components/Sections/NcOfferPackages/declarations';

const getParsersConfig = (): RichTextParsersConfig => {
  const getTitleClass = (headingClass: string) => cn(
    styles.offerContentBlockTitle,
    headingClass,
  );

  return ({
    [BLOCKS.HEADING_2]: {
      classNames: getTitleClass(styles.offerContentBlockTitleH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getTitleClass(styles.offerContentBlockTitleH3),
    },
    [BLOCKS.HEADING_4]: {
      classNames: getTitleClass(styles.offerContentBlockTitleH4),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

const getButtonParsersConfig = (rootClass: string = 'offerContentBlockButton'): RichTextParsersConfig => {
  const getClasses = (classes: string) => cn(styles[`${rootClass}Text`], classes);

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: getClasses(styles[`${rootClass}Paragraph`]),
    },
    [MARKS.ITALIC]: {
      classNames: getClasses(styles[`${rootClass}Currency`]),
      mapper: mapBlocksSpan,
    },
    [MARKS.BOLD]: {
      classNames: getClasses(styles[`${rootClass}Price`]),
    },
  });
};

const getRichTextTitle = (richText?: Block['content'] | Block['richTitle']) => {
  const rootDocument: DocumentNode | undefined = richText?.json;
  const headingContent: Array<BlockNode> | undefined = rootDocument?.content;

  if (!headingContent) {
    return '';
  }

  const textContent = headingContent
    .find(({ nodeType }) => nodeType.includes('heading'))?.content as Array<TextNode> | undefined;

  if (!textContent?.length) {
    return '';
  }

  const { value } = textContent.find(({ nodeType }) => nodeType.includes('text')) || {};

  if (!value) {
    return '';
  }

  const oneWordKey = value.split(' ')[0];

  return oneWordKey;
};

/* Nested component */
const Divider: FC = () => <div className={styles.offerContentBlockDivider} />;

/* Main component */
const CardNcOfferPackages: FC<CardNcOfferPackagesInterface> = ({
  block,
  onPackageClick,
}) => {
  const {
    slug,
    content,
    richTitle,
    title,
    link,
    textList,
    contentTypesCollection,
  } = block;

  const richTitleValue = getRichTextTitle(richTitle);
  const onClick = () => {
    onPackageClick(richTitleValue, link!, slug!);
  };
  const [mostPopular] = (contentTypesCollection?.items as Array<Block>) || [];

  const applyMostPopularAlert = () => mostPopular && (
    <span className={styles.offerContentBlockPopular}>
      {mostPopular.title}
    </span>
  );

  const applyRichText = (
    content?: BlockContent | BlockRichTitle,
    parserConfig: RichTextParsersConfig = getParsersConfig(),
  ) => content && (
    <CommonRichText
      content={content}
      parsersConfig={parserConfig}
    />
  );

  const applySubtitle = () => title && (
    <p className={styles.offerContentBlockDescription}>
      {title}
    </p>
  );

  const applyItemsList = () => textList && (
    <ul className={styles.offerContentBlockList}>
      {textList.map((text) => (
        <li key={text} className={styles.offerContentBlockListItem}>
          {text}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.offerContentWrapper}>
      {applyMostPopularAlert()}
      <div
        className={cn(
          styles.offerContentBlock,
          mostPopular && styles.offerContentBlockPopularBorder,
        )}
        itemScope
        itemType="https://schema.org/OfferForPurchase"
      >
        {applyRichText(richTitle!)}
        <Divider />
        {applySubtitle()}
        {applyItemsList()}
        <DataButton
          link={link!}
          onClick={onClick}
          className={styles.offerContentBlockButton}
        >
          {applyRichText(content!, getButtonParsersConfig())}
        </DataButton>
      </div>
    </div>
  );
};

export default CardNcOfferPackages;
